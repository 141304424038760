<template>
<vx-card title="Ballots">
    <template slot="actions">
        <vs-button @click="editSelected('')">Add</vs-button>
    </template>
    <vs-table stripe hoverFlat :data="ballotList" v-if="ballotList.length">
        <template #thead>
            <vs-th class="width1"></vs-th>
            <vs-th>Ballot Date</vs-th>
            <vs-th>Capacity</vs-th>
            <vs-th>Dates Open</vs-th>
            <vs-th>Status</vs-th>
            <vs-th>Ballot On</vs-th>
            <vs-th class="width1"></vs-th>
            <vs-th class="width1"></vs-th>
        </template>
        <template slot-scope="{data}">
            <vs-tr v-for="(tr, index) in ballotList" :key="index" :data="tr">
                <vs-td>
                    <vs-button color="dark" @click="editSelected(tr.id)">Edit</vs-button>
                </vs-td>
                <vs-td>
                    <span class="text-primary" @click="viewBallot(tr.id)">
                        {{tr.ballotDate | formatLongDate}}
                    </span>
                </vs-td>
                <vs-td>{{tr.capacity}}</vs-td>
                <vs-td>{{tr.startDate | formatLongDate}} to {{tr.endDate | formatLongDate}}</vs-td>
                <vs-td>{{tr.status}}</vs-td>
                <vs-td>
                  <vs-switch class="ml-2 m-2" color="success" v-model="tr.isTurnedOn" @click="handleBallot(tr.id)">
                      <span slot="on">Yes</span>
                      <span slot="off">No</span>
                    </vs-switch>
                </vs-td>
                 <vs-td>
                    <vs-button @click="viewBallot(tr.id)">View</vs-button>
                </vs-td>
                <vs-td>
                    <vs-button color="danger" @click="deleteSelected(tr.id)">Delete</vs-button>
                </vs-td>
            </vs-tr>
        </template>
    </vs-table>
    <NoData v-else class="mt-base" :headTitle="noData.title" :description="noData.description"></NoData>
</vx-card>
</template>

<script>
import {
    ballotData
} from "../../store/api/ballot";

export default {
    data() {
        return {
            selectedPass: undefined,
            openAddWindow: false,
            ballotList: [],
            noData: {
                title: "No Records Found",
            }
        }
    },
    components: {},
    async created() {
        await this.loadSeasonPass()
    },
    methods: {
        async loadSeasonPass() {
            this.ballotList = await ballotData.SearchBallot();
            if (this.openAddWindow) {
                this.openAddWindow = !this.openAddWindow;
            }
        },
        async deleteSelected(id) {
            if (!confirm("Delete the ballot only if has not begun/opened, are you sure ?")) {
                return;
            }

            let request = {
                data: {
                    id: id
                }
            }

            let apiResult = await ballotData.removeBallot(request);
            if (!apiResult.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: apiResult.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }
            await this.loadSeasonPass();
            this.$vs.notify({
                title: "Success",
                text: "Record Saved",
                color: "success",
                position: "top-center"
            });
        },
        editSelected(id) {
            this.$router.push(`/admin/ballot/add/${id}`);
        },
        viewBallot(id) {
            this.$router.push(`/admin/ballot/view/${id}`);
        },
        handleSelectedPass() {
            this.selectedPass = value;
        },
        async handleBallot(id)
        {
            let apiResult = await ballotData.toggleBallotDay(id);
            if (!apiResult.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: apiResult.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }
            await this.loadSeasonPass();
            this.$vs.notify({
                title: "Success",
                text: "Record Updated",
                color: "success",
                position: "top-center"
            });
        }
    }
}
</script>
